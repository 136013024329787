<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">검수관리</h2>
      </div>
      <div class="body_section">
        <!-- ref: 엑셀 다운로드에서 사용.. -->
        <InspectListFilterBar
          ref="filterBar"
          :statusList="statusList"
          :defaultStatusId="defaultStatusId"
          :keywordDatas="keywordList"
          :defaultKeywordSelectedId="defaultKeywordSelectedId"
          :defaultKeywordInputText="defaultKeywordInputText"
          @onClickSearch="onClickSearch"
        />

        <TableHead>
          <template v-slot:headRight>
            <SelectBoxPageRange
              :currentMaxRowCount.sync="size"
              @update:currentMaxRowCount="onChangeRowCount"
            />
          </template>
        </TableHead>
        <ListPage
          :list="dataList"
          :currentPageIndex="currentPageIndex"
          :totalPageCount="totalPageCount"
          :maxRowCount="size"
          :maxPaginationCount="maxPaginationCount"
          :isHorizontalScroll="true"
          :scrollWidth="'1400px'"
          @onChangePage="onChangePage"
        >
          <template v-slot:colgroup>
            <!-- 검수상태 -->
            <col style="width: 100px" />
            <!-- 검수차수 -->
            <col style="width: 60px" />
            <!-- 검수번호 -->
            <col style="width: 150px" />
            <!-- 발주번호 -->
            <col style="width: 150px" />
            <!-- 발주명 -->
            <col />
            <!-- 검수요청일 -->
            <col style="width: 80px" />
            <!-- 검수완료일 -->
            <col style="width: 80px" />
            <!-- 검수담당자 -->
            <col style="width: 160px" />
            <!-- 구매담당자 -->
            <col style="width: 160px" />
          </template>
          <template v-slot:tr>
            <th>검수상태</th>
            <th>검수차수</th>
            <th>검수번호</th>
            <th>발주번호</th>
            <th>발주명</th>
            <th>검수요청일</th>
            <th>검수완료일</th>
            <th>검수담당자</th>
            <th>구매담당자</th>
          </template>
          <template v-slot:row="slotProps">
            <InspectListLine :rowData="slotProps.rowData" />
          </template>
          <template v-slot:emptyList>
            <tr>
              <td colspan="9" class="td_empty">검수내역이 없습니다</td>
            </tr>
          </template>
        </ListPage>
      </div>
    </div>
    <template v-slot:popup>
      <AlertPopup
        v-if="isPopAlert"
        alertText="기간을 설정하지 않으셨습니다.<br>모든 데이터를 다운로드 하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCancelAlert"
        @onClickPrimary="onClickConfirmAlert"
      />
      <ExcelDonwloadPopup
        v-if="isPopExcel"
        :excelDataList="excelHeaderList"
        :checkedNames.sync="checkedNamesExcel"
        @onClickClose="onClickCloseExcel"
        @onClickDownload="onClickDownloadExcel"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import ListPage from "@/components/shared/ListPage";
import InspectListFilterBar from "@/components/user/inspect/list/InspectListFilterBar";
import InspectListLine from "@/components/user/inspect/list/InspectListLine";
import TableHead from "@/components/shared/TableHead";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";
import AlertPopup from "@/components/layout/popup/AlertPopup";
import ExcelDonwloadPopup from "@/components/layout/popup/ExcelDonwloadPopup";

import ApiService from "@/services/ApiService";
import LocalStorageManager from "@/LocalStorageManager";

import { dateToString, stringToDate } from "@/utils/dateUtils";
import excelKey from "@/constants/excelKey";

import { mapState } from "vuex";
import { INSPECT_LIST_ACTION } from "@/store/modules/inspect/action";

import PageMixin from "@/mixins/PageMixin";
import ListPageMixin from "@/mixins/ListPageMixin";
import InspectListMixin from "@/mixins/inspect/list/InspectListMixin";

export default {
  name: "InspectList",
  components: {
    PageWithLayout,
    InspectListFilterBar,
    TableHead,
    SelectBoxPageRange,
    ListPage,
    InspectListLine,
    AlertPopup,
    ExcelDonwloadPopup,
  },
  mixins: [PageMixin, ListPageMixin],
  data() {
    return {
      API_PATH: `${this.$apiPath.ST_INSPECT}`,

      statusList: [
        { code: "", name: "전체", desc: "전체" },
        { code: "INITIAL", name: "검수 요청 전", desc: "검수 요청 전" },
        { code: "INSPECT_REQUEST", name: "검수 요청", desc: "검수 요청" },
        { code: "INSPECT_APPROVE", name: "검수 승인", desc: "검수 승인" },
        { code: "INSPECT_REJECT", name: "검수 반려", desc: "검수 반려" },
        { code: "PURCHASE_APPROVE", name: "구매 검수 승인", desc: "구매 검수 승인" },
        { code: "PURCHASE_REJECT", name: "구매 검수 반려", desc: "구매 검수 반려" },
        { code: "INSPECT_COMPLETE", name: "검수 완료", desc: "검수 완료" },
        {
          code: "INSPECT_REQUEST_CANCEL",
          name: "검수 요청 취소",
          desc: "검수 요청 취소",
        },
        { code: "INSPECT_DELETE", name: "검수 취소", desc: "검수 취소" },
      ],
      defaultStatusId: "",
      keywordList: [
        { id: "title", code: "title", desc: "발주명" },
        { id: "orderCid", code: "orderCid", desc: "발주번호" },
        { id: "inspectNum", code: "inspectNum", desc: "검수번호" },
      ],
      defaultKeywordSelectedId: "title",
    };
  },
  computed: {},
  created() {
    const params = this.checkUrlQuery();
    this.getDataInit(params);
  },
  methods: {
    checkUrlQuery() {
      const { query } = this.$route;
      const objPaging = this.checkQueryPaging(query);
      const objKeyword = this.checkQueryKeywords(query, this.keywordList);
      const objDates = this.checkQueryDates(query);
      const objStatus = this.checkQueryStatus(query);

      return Object.assign({}, objPaging, objKeyword, objDates, objStatus);
    },
    getFilterbarParams(objData, isForExcel) {
      const { keywordSelectedId, keywordInputText, startDt, endDt, status } = objData;

      let params = {};

      if (keywordSelectedId && keywordInputText) {
        params[keywordSelectedId] = keywordInputText;
      }

      if (startDt && endDt) {
        params["startDate"] = startDt;
        params["endDate"] = endDt;
      }
      if (status) {
        params.status = status;
      }

      return params;
    },
  },
};
</script>
