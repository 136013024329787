<template>
  <ListRow>
    <!-- 검수상태 -->
    <td>{{ computedStatusName }}</td>
    <!-- 검수차수 -->
    <td>{{ rowData.degree }}</td>
    <!-- 검수번호 -->
    <td class="td_ellip" :data-ellip="rowData.inspectNum">
      <router-link
        :to="toPath"
        class="link_subject align_center"
        :data-ellip="rowData.inspectNum"
        v-html="rowData.inspectNum"
      />
    </td>
    <!-- 발주번호 -->
    <td class="td_ellip">
      <button
        class="link_subject align_center"
        :data-ellip="rowData.orderCid"
        @click="$windowOpen(`${$routerPath.ORDER_VIEW}/${rowData.orderCid}`)"
      >
        {{ rowData.orderCid }}
      </button>
    </td>
    <!-- 발주명 -->
    <td class="align_left">{{ rowData.title }}</td>
    <!-- 파트너사 -->
    <!-- <td class="td_ellip" :data-ellip="rowData.companyName">{{ rowData.companyName }}</td> -->
    <td>{{ rowData.inspectRequestDate | dateStringFormat }}</td>
    <td>{{ rowData.inspectCompleteDate | dateStringFormat }}</td>
    <td>{{ rowData.checker }}</td>
    <td>{{ rowData.buyer }}</td>
  </ListRow>
</template>

<script>
import ListRow from "@/components/shared/ListRow";
import ListLineMixin from "@/mixins/ListLineMixin";
import { getDateString } from "@/utils/dateUtils";
import { makePathWithQuery } from "@/utils/urlUtils";

export default {
  name: "InspectListLine",
  components: {
    ListRow,
  },
  mixins: [ListLineMixin],
  props: {
    rowData: Object,
  },
  computed: {
    toPath() {
      const { stateCode, inspectNum } = this.rowData;
      let path = ``;
      if (stateCode === "INITIAL") {
        // 작성으로 이동
        const routerPath = this.$routerPath.INSPECT_WRITE;
        path = makePathWithQuery(routerPath, `${inspectNum}`, this.$route.query);
      } else {
        // 뷰로 이동
        const routerPath = this.$routerPath.INSPECT_VIEW;
        path = makePathWithQuery(routerPath, `${inspectNum}`, this.$route.query);
      }

      return path;
    },

    computedStatusName() {
      let ret = "";
      switch (this.rowData.stateCode) {
        case "INITIAL":
          ret = "검수 요청전";
          break;
        case "INSPECT_REQUEST":
          ret = "검수 요청";
          break;
        case "INSPECT_APPROVE":
          ret = "검수 승인";
          break;
        case "INSPECT_REJECT":
          ret = "검수 반려";
          break;
        case "PURCHASE_APPROVE":
          ret = "구매 검수 승인";
          break;
        case "PURCHASE_REJECT":
          ret = "구매 검수 반려";
          break;
        case "INSPECT_COMPLETE":
          ret = "검수 완료";
          break;
        case "INSPECT_DELETE":
          ret = "검수 취소";
          break;
        default:
          break;
      }
      return ret;
    },
    computedGubunDate() {
      const { gubun, contractStartDate, contractEndDate, goodsDeadLine } = this.rowData;
      let ret = ``;
      if (gubun === "C") {
        ret = `${getDateString(contractStartDate)} ~ ${getDateString(contractEndDate)}`;
      } else {
        ret = `${getDateString(goodsDeadLine)}`;
      }
      return ret;
    },
    computedGubunNumber() {
      const { gubun, inspectNum, upperId } = this.rowData;
      let ret = ``;
      if (gubun === "C") {
        ret = `${upperId}(${inspectNum})`;
      } else {
        ret = `${upperId}`;
      }
      return ret;
    },
    // stateType() {
    //   switch (this.rowData.status) {
    //     case 'G':
    //       return 'on';
    //     case 'C':
    //       return 'on';
    //     case 'T':
    //       return '';
    //     case 'E':
    //       return 'disabled';
    //   }
    //   return '';
    // },
  },
};
</script>
