import { mapState } from "vuex";
// import { ANNOUNCE_LIST_ACTION } from '@/store/modules/announce/action';
import ListPageMixin from "@/mixins/ListPageMixin";

const InspectListMixin = {
  mixins: [ListPageMixin],
  data() {
    return {};
  },

  computed: {},
  methods: {
    // 주소 기준으로 현재 페이지에 필요한 params 가져온다.
    // 주소 기준으로 필터바 초기값을 셋팅한다.
    checkUrlQuery() {
      const { query } = this.$route;
      const objPaging = this.checkQueryPaging(query);
      const objstate = this.checkQueryState(query);
      const objKeyword = this.checkQueryKeywords(query, this.keywordList);
      const objDates = this.checkQueryDates(query, "startDate", "endDate");

      return Object.assign({}, objstate, objKeyword, objDates, objPaging); //objPaging,
    },
    getFilterbarParams(objData) {
      const { keywordSelectedId, keywordInputText, startDt, endDt, stateCode } = objData;
      let params = {};

      if (keywordSelectedId && keywordInputText) {
        params[keywordSelectedId] = keywordInputText;
      }
      if (startDt && endDt) {
        params["startDate"] = startDt;
        params["endDate"] = endDt;
      }

      if (stateCode) {
        params.status = stateCode;
      }
      return params;
    },

    checkQueryState(query) {
      const { stateCode } = query;
      let ret = {};
      if (stateCode) {
        ret = { stateCode: stateCode };
        this.defaultStateId = stateCode;
      } else {
        ret = {
          stateCode: this.defaultStateId,
        };
      }

      return ret;
    },
  },
};
export default InspectListMixin;
